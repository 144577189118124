var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Nicename",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.nicename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nicename", $$v);
      },
      expression: "form.nicename"
    }
  }), !_vm.resource ? _c('v-select', {
    attrs: {
      "items": _vm.siteOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Replicate from",
      "outlined": ""
    },
    model: {
      value: _vm.form.site_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "site_id", $$v);
      },
      expression: "form.site_id"
    }
  }) : _vm._e(), _c('v-switch', {
    attrs: {
      "color": "primary",
      "label": _vm.form.main ? 'is Main' : 'is not Main',
      "disabled": !_vm.resource
    },
    model: {
      value: _vm.form.main,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "main", $$v);
      },
      expression: "form.main"
    }
  }), _c('CountryIdFilter', {
    attrs: {
      "clearable": false,
      "select-base": !_vm.resource
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }